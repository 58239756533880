parse_countries({
	'countries': [
		{
			'name': 'Andersen Global',
			'link': 'http://www.andersen.com'
		},
		{
			'name': 'Brazil',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Canada*',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Chile',
			'link': 'http://www.google.com'
		},
		{
			'name': 'France',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Germany',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Guatemala',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Ireland*',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Italy',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Luxembourg',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Mexico',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Netherlands',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Panama',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Poland',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Russia',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Spain',
			'link': 'http://www.google.com'
		},
		{
			'name': 'Switzerland',
			'link': 'http://www.google.com'
		},
		{
			'name': 'United States',
			'link': 'http://www.google.com'
		},
	]
});
